import "./CtaConnectWithUs.css";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

function CtaConnectWithUs() {
  const navigate = useNavigate();

  function onCTAClicked() {
    navigate(`/contact`);
    window.scrollTo(0, 0);
  }

  return (
    <div className="project-details-cta-for-callback">
      <div className="project-details-cta-for-callback-circles-bg-container">
        <div className="circles-bg-large"></div>
        <div className="circles-bg-med"></div>
        <div className="circles-bg-small"></div>
      </div>
      <div className="cta-text-container">
        <p className="title brand-font-primary">
          Interested?<br></br>Connect with us today
        </p>
        <p className="body">
          Take the first step towards your dream home interiors.
        </p>
      </div>
      {/* CTA for inquiry and contact */}
      <div>
        <Button
          color="secondary"
          onClick={onCTAClicked}
          size="lg"
          variant="outlined"
          startIcon={<CallOutlinedIcon />}
        >
          Get a call
        </Button>
      </div>
    </div>
  );
}

export default CtaConnectWithUs;
