import "./WorkWithUs.css";
import axios from "axios";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { MuiFileInput } from "mui-file-input";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import routes from "../../const/routes";

function WorkWithUs() {
  const [loading, setLoading] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);

  const onResumeFileChange = (newFile) => {
    setResumeFile(newFile);
  };

  function onCareerFormSubmit(event) {
    event.preventDefault();
    const apiUrl = "https://elitenooks.com/api/website/process_career_form.php";

    const form = document.forms.CareerForm;
    const formData = new FormData(form);

    setLoading(true);

    axios({
      method: "post",
      url: apiUrl,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        form.reset();
        setLoading(false);
        window.alert("Request received, you'll be contacted soon.");
      })
      .catch(function (response) {
        //handle error
        setLoading(false);
        window.alert("Something went wrong, please try again.");
      });
  }

  return (
    <div className="work-with-us-parent-container">
      <div className="work-with-us-container max-inner-width">
        <div className="work-with-us-text-container">
          <GroupsOutlinedIcon className="work-with-us-icon" />
          <p className="work-with-us-title">Want to work with us?</p>
          <p className="work-with-us-subtitle">
            All bright minds are welcome at Elitenooks!
            <br />
            Share your details and we'll get back to you.
          </p>
        </div>
        <div className="work-with-us-form">
          <form id="CareerForm" onSubmit={onCareerFormSubmit}>
            <div className="work-with-us-form-section">
              {/* Name */}
              <TextField
                className="contact-form-input"
                label="Full name"
                variant="outlined"
                type="text"
                name="name"
                required
              />
              {/* Phone */}
              <TextField
                className="contact-form-input"
                label="Phone"
                variant="outlined"
                type="phone"
                name="phone"
                required
              />
            </div>
            <div className="work-with-us-form-section">
              {/* Email */}
              <TextField
                className="contact-form-input"
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                required
              />
              {/* Resume */}
              <MuiFileInput
                className="contact-form-input"
                value={resumeFile}
                placeholder="Upload resume"
                InputProps={{
                  inputProps: {
                    accept: "image/*, .pdf",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
                onChange={onResumeFileChange}
              />
            </div>

            {/* Message */}
            <TextareaAutosize
              minRows={4}
              maxRows={10}
              aria-label="maximum height"
              placeholder="Express your thoughts here..."
              defaultValue=""
              name="message"
            />
            <p>
              By submitting this form, I agree with the{" "}
              <a href={routes.legal.privacyPolicy}>Privacy Policy</a>.
            </p>
            <LoadingButton
              className="submit_button"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              loading={loading}
            >
              {loading ? "" : "Submit"}
            </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default WorkWithUs;
