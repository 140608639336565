import "./ContactRequestResult.css";

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "../../const/routes";

function ContactRequestResult() {
  const navigate = useNavigate();

  function onGoHome() {
    navigate(routes.home);
  }

  return (
    <>
      <div className="contact-request-result-parent-container">
        <div className="contact-request-result-inner-container">
          <div className="section-title contact-request-result-body-section">
            <p className="section-subtitle"></p>
            <p className="section-title-text brand-font-primary">
              Thank You
            </p>
            <p className="section-body">
              Thank you for submitting your contact request. We'll try to get back to you shortly.
            </p>
          </div>
          <Button
              variant="contained"
              color="primary"
              onClick={onGoHome}
            >
              Go to home
            </Button>
        </div>
      </div>
    </>
  );
}

export default ContactRequestResult;
