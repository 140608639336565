import "./About.css";

import axios from "axios";

import { Button } from "@mui/material";
import { useEffect, useState } from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Loader from "../../components/Loader/Loader";
import Partners from "../partners/Partners";
import FeaturedProjects from "../../components/FeaturedProjects/FeaturedProjects";

function About() {
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState({});

  useEffect(() => {
    fetchTeamDetails();
  }, []);

  async function fetchTeamDetails() {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://elitenooks.com/api/get_team.php",
        {
          validateStatus: function (_) {
            return true;
          },
        }
      );
      if (response.status === 200) {
        setTeamData(response.data);
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <Loader />;

  return (
    <>
      <div className="about-parent-container">
        <div className="about-inner-container">
          <div className="section-title about-body-section">
            <p className="section-subtitle"></p>
            <p className="section-title-text brand-font-primary">
              About Elitenooks
            </p>
            <p className="section-body">
              {/* Right from interior to styling. We take care of everything! */}
            </p>
          </div>

          <div className="about-intro-paragraph-container about-body-section max-inner-width">
            <p className="about-intro-paragraph">
            Elite Nooks is an interior design firm that is dedicated to making style, function, and comfort effortlessly accessible for you, infusing luxury into every corner of your home. In today’s fast-paced world, many working couples have limited time for extra responsibilities but still value the joy of hosting gatherings and relaxing with loved ones at home. Recognizing this, we created Elite Nooks as a solution for busy individuals seeking a stylish, functional, and inviting home setup without the stress.
            </p>

            {/* <h2 className="about-intro-tagline brand-font-primary">Luxury in every corner</h2> */}

            <br></br>
            
            <p className="about-intro-paragraph">
            Elite Nooks is characterized by these distinctive unique features:
            ·  Trend-Sync Design Boards: Isn’t it exhausting to keep up with the latest trends and ensure everything is Vaastu-compliant? We’ve got you covered with multiple design and style boards that sync with current trends and Vaastu-friendly color combinations.
            </p>

            <ul className="about-intro-paragraph">
              <li><p><b>All selections under one roof</b>: Tired of running from store to store? Imagine having all your selections under one roof. Elite Nooks simplifies the decision-making process by offering everything you need in one place.</p></li>
              <li><p><b>Hassle-Free Execution</b>: Ever been overwhelmed by onsite chaos? Our 80% factory-prepared, ready-to-assemble solutions ensure seamless and stress-free onsite execution.</p></li>
              <li><p><b>Transparent Deliverables</b>: Worried about the outcome not matching your vision? With our detailed design dockets, what you see is what you get. Plus, we include warranties and specifications for complete peace of mind.</p></li>
              <li><p><b>Stylish Living</b>: At Elite nooks, we believe that lifestyle is reflected not just in cars and clothes, but also in the places we live. A thoughtfully decorated home creates a welcoming environment for socializing and entertaining, enhancing the overall quality of life</p></li>
              <li><p><b>Completion within 45 working days</b>: Anxious about project delays? Say goodbye to waiting with our guaranteed project completion within 45 working days.</p></li>
            </ul>

            <p className="about-intro-paragraph">
            We are backed by AndSpaces, our parent company, with a 5-year portfolio, multiple awards, and several projects in our library. You can learn more about our parent company at <a href="https://www.andspaces.in/" target="_blank" rel="noreferrer">andspaces.in</a>  or Instagram handle <a href="https://www.instagram.com/official_andspaces/" target="_blank" rel="noreferrer">official_andspaces</a>.
            </p>
          </div>
          
          <div className="about-directors-container">
            <div className="about-directors-container-inner about-body-section max-inner-width">
              <p className="about-directors-section-title brand-font-primary">Minds behind Elitenooks</p>
              <div className="about-directors-grid-view">
                {
                  teamData?.directors?.map((profile) => (
                    <div className="about-directors-grid-item">
                      <div className="about-directors-grid-item-header">
                        <div className="about-directors-grid-item-picture" style={{
                          backgroundImage: `url(${profile.picture})`,
                        }}></div>
                        <div className="about-directors-grid-item-details">
                          <p className="about-directors-grid-item-details-title">{profile.name}</p>
                          <p className="about-directors-grid-item-details-subtitle">{profile.designation}</p>
                          {profile.link && <Button variant="contained" className="about-directors-grid-item-details-cta" href={profile.link} target="_blank" startIcon={<LinkedInIcon/>}>Connect</Button>}
                        </div>
                      </div>
                      <p className="about-directors-grid-item-message">{profile.message}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          
          <div className="about-employees-container about-body-section max-inner-width">
            <p className="about-employees-section-title brand-font-primary">Meet the Team</p>
            <div className="about-employees-grid-view">
              {
                teamData?.employees?.map((profile) => (
                  <div className="about-employees-grid-item">
                    <div className="about-employees-grid-item-picture" style={{
                        backgroundImage: `url(${profile.picture})`,
                      }}></div>
                    <p className="about-employees-grid-item-details-title">{profile.name}</p>
                    <p className="about-employees-grid-item-details-subtitle">{profile.designation}</p>
                  </div>
                ))
              }
            </div>
          </div>

          <Partners/>

          <FeaturedProjects />
        </div>
      </div>
    </>
  );
}

export default About;
