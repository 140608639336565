import "./Packages.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function Packages() {
  const [loading, setLoading] = useState(true);
  const [packageData, setPackageData] = useState([]);
  const [featuresData, setFeaturesData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    onInit();
  }, []);

  function getFeatureValueRepresentation(value) {
    if (value === null || value === "" || value === "false") {
      return <CancelIcon className="package-excludes-icon" />;
    }
    if (value === "true") {
      return <CheckCircleIcon className="package-includes-icon" />;
    }
    return <p>{value}</p>;
  }

  function onPackageSelected(packageId) {
    //TODO: Navigate
  }

  async function onInit() {
    try {
      setLoading(true);
      await fetchPackages();
    } catch (e) {
      //TODO:  Handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPackages() {
    axios
      .get("https://elitenooks.com/api/get_packages.php", {
        validateStatus: function (_) {
          return true;
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPackageData(response.data?.packages);
          setFeaturesData(response.data?.features);
        } else {
          //TODO: Handle this
        }
      });
  }

  return (
    <>
      <div id="packages-view" className="packages-parent-container">
        <div className="packages-inner-container max-inner-width">
          <div className="packages-section-title">
            <p className="packages-section-title-text brand-font-primary">
              Packages
            </p>
            <p className="packages-section-subtitle-text">
              Explore various packages we have in offer.
            </p>
            <Button
              variant="text"
              className="packages-section-see-more-cta"
              href="/packages"
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              See in action
            </Button>
          </div>
          <table className="packages-table">
            <thead>
              <tr className="package-table-header">
                <th></th>
                {packageData?.map((item) => (
                  <th>
                    <img
                      className="package-icon"
                      src={item.icon}
                      alt={item.title}
                    />
                    <p className="package-title">{item.title}</p>
                  </th>
                ))}
              </tr>
              {/* <tr>
                <th></th>
                {packageData?.map((item) => (
                  <th>
                    <p className="package-description">{item.description}</p>
                  </th>
                ))}
              </tr> */}
              <tr>
                <td></td>
                <td colspan="3">
                  <p className="packages-table-section-title">Interior</p>
                </td>
              </tr>
            </thead>
            <tbody className="packages-table-body">
              {featuresData?.interior?.map((feature) => (
                <tr>
                  <td className="package-feature-title">{feature.title}</td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_essential"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_signature"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_elite"]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr>
                <td></td>
                <td colspan="3">
                  <p className="packages-table-section-title">Styling</p>
                </td>
              </tr>
            </thead>
            <tbody className="packages-table-body">
              {featuresData?.styling?.map((feature) => (
                <tr>
                  <td className="package-feature-title">{feature.title}</td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_essential"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_signature"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_elite"]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody className="packages-table-body">
              <tr>
                <td className="package-feature-title"></td>
                <td style={{ padding: "8px 0" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    href="/packages/2/3"
                    // endIcon={<ChevronRightIcon />}
                  >
                    See More
                  </Button>
                </td>
                <td style={{ padding: "8px 0" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    href="/packages/2/2"
                    // endIcon={<ChevronRightIcon />}
                  >
                    See More
                  </Button>
                </td>
                <td style={{ padding: "8px 0" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    href="/packages/2/1"
                    // endIcon={<ChevronRightIcon />}
                  >
                    See More
                  </Button>
                </td>
              </tr>
            </tbody>
            {/* <thead>
              <tr>
                <td colspan="4">
                  <p className="packages-table-section-title">Other</p>
                </td>
              </tr>
            </thead>
            <tbody className="packages-table-body">
              {featuresData?.other?.map((feature) => (
                <tr>
                  <td className="package-feature-title">{feature.title}</td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_essential"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_signature"]
                    )}
                  </td>
                  <td>
                    {getFeatureValueRepresentation(
                      feature["included_in_elite"]
                    )}
                  </td>
                </tr>
              ))}
            </tbody> */}
          </table>
          <div className="packages-grid-view">
            {packageData?.map((item) => (
              <div className="packages-grid-item">
                <img
                  className="package-icon"
                  src={item.icon}
                  alt={item.title}
                />
                <p className="package-title">{item.title}</p>
                <p className="package-description">{item.description}</p>
                <div className="package-features">
                  <p>Interior</p>
                  <List dense={true} style={{ marginBottom: "32px" }}>
                    {featuresData?.interior?.map((feature) => (
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {feature["included_in_essential"] ? (
                              <CheckCircleIcon />
                            ) : (
                              <CancelIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={feature.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <p>Styling</p>
                  <List dense={true} style={{ marginBottom: "32px" }}>
                    {featuresData?.styling?.map((feature) => (
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleIcon />
                          </ListItemIcon>
                          <ListItemText primary={feature.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </div>
                {/* <Button
                  className="package-cta"
                  variant="contained"
                  color="primary"
                  size="medium"
                >
                  Explore
                </Button> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Packages;
