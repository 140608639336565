import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="PrivacyPolicyView max-inner-width-reduced">
        <h1 className="title">Privacy Policy</h1>
        <p className="sub-title">
          <strong>Effective Date:</strong> 23 May 2024
        </p>

        <div className="PrivacyPolicyBody">
          <h2>1. Introduction</h2>
          <p>
            Elitenooks ("we", "our", "us") is committed to protecting your
            privacy. This Privacy Policy outlines how we collect, use, disclose,
            and safeguard your information when you visit our website{" "}
            <a href="https://elitenooks.com">elitenooks.com</a>.
          </p>

          <h2>2. Information We Collect</h2>
          <p>
            <strong>Personal Information:</strong> We collect personal
            information that you provide to us, such as name, email address, and
            phone number, through our contact forms.
          </p>
          <p>
            <strong>Usage Data:</strong> We collect information automatically
            when you visit our website, such as your IP address, browser type,
            operating system, and browsing behavior.
          </p>

          <h2>3. How We Use Your Information</h2>
          <p>We use the information we collect in the following ways:</p>
          <ul>
            <li>
              <strong>To Provide and Maintain Our Services:</strong> We use your
              information to respond to your inquiries, provide customer
              support, and fulfill your requests.
            </li>
            <li>
              <strong>To Improve Our Services:</strong> We analyze usage data to
              enhance user experience and website functionality.
            </li>
            <li>
              <strong>Marketing and Communication:</strong> With your consent,
              we may send promotional materials and updates.
            </li>
          </ul>

          <h2>4. Sharing Your Information</h2>
          <p>
            We do not sell or share your personal information with third
            parties, except in the following circumstances:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> We may share information with
              vendors who perform services on our behalf.
            </li>
            <li>
              <strong>Legal Obligations:</strong> If required by law or to
              protect our rights.
            </li>
          </ul>

          <h2>5. Security of Your Information</h2>
          <p>
            We use administrative, technical, and physical security measures to
            protect your personal information. Despite these measures, no
            website, internet transmission, computer system, or wireless
            connection is completely secure.
          </p>

          <h2>6. Your Rights</h2>
          <p>
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>
              <strong>Access and Correction:</strong> You can access and update
              your personal information at any time by contacting us.
            </li>
            <li>
              <strong>Opt-Out:</strong> You can opt-out of receiving marketing
              communications from us by following the unsubscribe instructions
              included in our emails.
            </li>
          </ul>

          <h2>7. Third-Party Services</h2>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or the content of these
            websites. Please review the privacy policies of these third-party
            services before providing any information.
          </p>

          <h2>8. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our website and hold certain information. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our website.
          </p>

          <h2>9. Changes to This Privacy Policy</h2>
          <p>
            We may update this policy periodically. We will notify you of any
            changes by posting the new Privacy Policy on this page and updating
            the effective date.
          </p>

          <h2>10. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p>Email: info@elitenooks.com</p>
          <p>
            Address: Block No 52, 1194/9, Flat No 2, Ghole Rd, Shubhashri
            Residency, Shivajinagar, Pune, Maharashtra 411005
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
