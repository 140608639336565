import "./BeforeAfterElitenooks.css";
import imageBefore from "../../asset/images/before-and-after/before_2.jpg";
import imageMiddle from "../../asset/images/before-and-after/middle.jpg";
import imageAfter from "../../asset/images/before-and-after/after.jpg";

import { Button } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function BeforeAfterElitenooks() {
  return (
    <div id="before-after-view" className="before-after-parent-container">
      <div className="before-after-inner-container">
        <div className="section-title">
          <div
            className="background"
            style={{ backgroundImage: `url(${imageBefore})` }}
          ></div>
          <p className="section-title-text brand-font-primary">
            We're here to make<br></br>Your World,<br></br> a better place to
            live!
          </p>
          <p className="section-subtitle-text">
            We bring our designs in a way, where every corner speaks luxury.
          </p>
        </div>
        <div className="before-after-section">
          <div
            className="picture"
            style={{ backgroundImage: `url(${imageBefore})` }}
          ></div>
          <div className="text">
            <h3 className="brand-font-primary">Before</h3>
            <p>Empty house with virtually nothing exciting!</p>
            <Button
              variant="outlined"
              color="light"
              href="/project/3"
              endIcon={<ChevronRightIcon />}
            >
              See project
            </Button>
          </div>
        </div>
        <div className="before-after-section">
          <div
            className="picture"
            style={{ backgroundImage: `url(${imageMiddle})` }}
          ></div>
          <div className="text">
            <h3 className="brand-font-primary">And</h3>
            <p>Work in progress. Awesomeness is on the way!</p>
            <Button
              variant="outlined"
              color="light"
              href="/project/3"
              endIcon={<ChevronRightIcon />}
            >
              See project
            </Button>
          </div>
        </div>
        <div className="before-after-section">
          <div
            className="picture"
            style={{ backgroundImage: `url(${imageAfter})` }}
          ></div>
          <div className="text">
            <h3 className="brand-font-primary">After</h3>
            <p>Your home is waiting for you to walk-in!</p>
            <Button
              variant="outlined"
              color="light"
              href="/project/3"
              endIcon={<ChevronRightIcon />}
            >
              See project
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeforeAfterElitenooks;
