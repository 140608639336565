import "./Header.css";
import logoDark from "../../asset/brand/logo_dark.svg";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import routes from "../../const/routes";
import contactDetails from "../../const/contact_details";
import DotsPatternBackground from "../../asset/images/dots_pattern_2.png";

function Header() {
  const navigate = useNavigate();
  const [hoverMenuOpen, setHoverMenuOpen] = useState(false);
  const [opaqueHeader, setOpaqueHeader] = useState(
    window.location.pathname === "/"
  );

  const menu = [
    {
      label: "Home",
      path: routes.home,
      forDesktop: true,
      forMobile: true,
    },
    {
      label: "About",
      path: routes.about,
      forDesktop: true,
      forMobile: true,
    },
    {
      label: "Projects",
      path: routes.projects,
      forDesktop: true,
      forMobile: true,
    },
    {
      label: "Packages",
      path: routes.packages.replace("/:type?", "").replace("/:package_id?", ""),
      forDesktop: true,
      forMobile: true,
    },
    {
      label: "Contact Us",
      path: routes.contact,
      forDesktop: true,
      forMobile: true,
    },
  ];

  function toggleHoverMenu() {
    setHoverMenuOpen(!hoverMenuOpen);
  }

  function onClickMenuItem(path) {
    if (hoverMenuOpen) {
      toggleHoverMenu();
    }
    setOpaqueHeader(path === "/");
    navigate(path);
    window.scrollTo(0, 0);
  }

  function onScroll(_) {
    if (window.location.pathname === "/" && window.scrollY <= 150) {
      setOpaqueHeader(true);
    } else {
      setOpaqueHeader(false);
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
  }, []);

  return (
    <header
      className={`header ${
        opaqueHeader ? "opaque" : ""
      }  animate__animated animate__fadeInDown`}
      style={{
        backgroundImage: opaqueHeader ? "" : `url(${DotsPatternBackground})`,
      }}
    >
      <a href="/" className="header-logo-container">
        <div className="header-logo-inner-div">
          <img src={logoDark} className="brand-logo" alt="logo" />
        </div>
        <div className="brand-name-container">
          <p className="brand-name">Elitenooks</p>
          <p className="brand-subtitle">By AndSpaces</p>
        </div>
      </a>
      <div className="header-main-menu large-screen-only">
        {menu
          .filter((item) => item.forDesktop)
          .map((menu) => (
            <Button
              // href={menu.path}
              onClick={() => onClickMenuItem(menu.path)}
              key={menu.path}
              className="header-button"
              variant="text"
              color="primary"
            >
              {menu.label}
            </Button>
          ))}
      </div>
      <div className="header-rhs-cta large-screen-only">
        {/* <p>Get in touch:</p> */}
        <div className="header-social-media-links-container">
          <a
            rel="noreferrer"
            href={contactDetails.socialMedia.instagram}
            target="_blank"
          >
            <InstagramIcon />
          </a>
          <a
            rel="noreferrer"
            href={contactDetails.socialMedia.facebook}
            target="_blank"
          >
            <FacebookIcon />
          </a>
          <a
            rel="noreferrer"
            href={contactDetails.socialMedia.linkedin}
            target="_blank"
          >
            <LinkedInIcon />
          </a>
        </div>
        {/* <Button
          // href={menu.path}
          onClick={() => onClickMenuItem(routes.contact)}
          key={routes.contact}
          className="header-button"
          variant="contained"
          size="medium"
          style={{ borderRadius: "54px" }}
          color={opaqueHeader ? "secondary" : "primary"}
        >
          Contact Us
        </Button> */}
      </div>
      <div className="header-menu-toggle-container small-screen-only">
        <Button
          className="header-menu-toggle-button"
          color="primary"
          onClick={toggleHoverMenu}
        >
          <MenuOutlinedIcon className="header-menu-toggle-icon" />
        </Button>
      </div>
      <div className={`header-hover-menu ${hoverMenuOpen ? "open" : ""}`}>
        <div className="header-menu-toggle-container">
          <Button
            className="header-menu-toggle-button"
            onClick={toggleHoverMenu}
          >
            <CloseOutlinedIcon className="header-menu-toggle-icon" />
          </Button>
        </div>
        <div className="header-main-menu">
          {menu
            .filter((item) => item.forMobile)
            .map((menu) => (
              <Button
                // href={menu.path}
                onClick={() => onClickMenuItem(menu.path)}
                key={menu.path}
                className="header-button"
                size="large"
              >
                {menu.label}
              </Button>
            ))}
        </div>
        <div className="header-rhs-cta">
          <p>Get in touch:</p>
          <div className="header-social-media-links-container">
            <a
              rel="noreferrer"
              href={contactDetails.socialMedia.instagram}
              target="_blank"
            >
              <InstagramIcon />
            </a>
            <a
              rel="noreferrer"
              href={contactDetails.socialMedia.facebook}
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              rel="noreferrer"
              href={contactDetails.socialMedia.linkedin}
              target="_blank"
            >
              <LinkedInIcon />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
