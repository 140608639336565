const routes = {
  home: "/",
  about: "about",
  projects: "/projects",
  project: "/project/:id",
  packages: "/packages/:type?/:package_id?",
  packagesExplore: "/packages-explore/:type/:package_id?/:room?",
  contact: "/contact",
  contactRequestResult: "/contact-request-submission",
  legal: {
    privacyPolicy: "legal/privacy-policy",
  },
  unknown: "*",
};

export default routes;
