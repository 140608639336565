import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./PackageSelector.css";
// import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
// import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import CircularProgress from "@mui/material/CircularProgress";

function PackageSelector() {
  const { type, package_id, room } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(
    +(package_id ?? 0) - 1
  );
  const [selectedRoom, setSelectedRoom] = useState(+(room ?? 0) - 1);
  const [selectedPicture, setSelectedPicture] = useState(0);
  const [previewPicture, setPreviewPicture] = useState();
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    refreshPicture();
  }, [packageData, selectedPackage, selectedRoom, selectedPicture]);

  async function onInit() {
    try {
      setLoading(true);
      await fetchPackages();
    } catch (e) {
      //TODO:  Handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPackages() {
    const response = await axios.get(
      "https://elitenooks.com/api/get_packages.php?type=" + type,
      {
        validateStatus: function (_) {
          return true;
        },
      }
    );
    if (response.status === 200) {
      if (response.data?.packages) {
        response.data.packages = response.data.packages.reverse();
      }
      setPackageData(response.data);
    } else {
      //TODO: Handle this
    }
  }

  function onPackageSelected(_, value) {
    if (value === null) {
      return;
    }
    setSelectedPackage(value ?? 0);
    if (!packageData?.packages?.at(value)?.rooms?.at(selectedRoom)) {
      onRoomSelected();
    }
    if (
      !packageData?.packages
        ?.at(value)
        ?.rooms?.at(selectedRoom)
        ?.pictures?.at(selectedPicture)?.picture
    ) {
      onPictureSelected();
    }
  }

  function onRoomSelected(_, value) {
    if (value === null) {
      return;
    }
    setSelectedRoom(value ?? 0);
    onPictureSelected();
  }

  function onPictureSelected(_, value) {
    if (value === null) {
      return;
    }
    setSelectedPicture(value ?? 0);
  }

  function refreshPicture() {
    const url = packageData?.packages
      ?.at(selectedPackage)
      ?.rooms?.at(selectedRoom)
      ?.pictures?.at(selectedPicture)?.picture;
    setPreviewPicture(url);
  }

  return (
    <>
      <div className="package-selector-parent-container">
        <div className="package-selector-loader">
          <CircularProgress color="primary" />
        </div>

        <div
          className="package-room-picture-preview"
          style={{
            backgroundImage: "url(" + previewPicture + ")",
          }}
        ></div>

        <div className="package-selection-container">
          <div className="package-selector room-selector" style={{ right: 0 }}>
            <div
              className="room-selector-inner-container"
              style={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <p className="package-selector-title">Room</p>
              <ToggleButtonGroup
                id="package-room-radio-group"
                type="radio"
                name="rooms"
                size="small"
                orientation="vertical"
                color="secondary"
                defaultValue={0}
                exclusive={true}
                value={selectedRoom}
                onChange={onRoomSelected}
              >
                {packageData?.packages
                  ?.at(selectedPackage)
                  ?.rooms?.map((room, index) => (
                    <ToggleButton
                      key={index}
                      id={`package-room-radio-${index}`}
                      variant="outline-light"
                      name="room-radio"
                      value={index}
                      checked={selectedRoom === index}
                    >
                      <div
                        className={`package-selector-toggle${
                          selectedRoom === index ? " active" : ""
                        }`}
                      >
                        <img
                          src={room.icon}
                          alt="Package room interior preview"
                        />
                        <p>{room.title}</p>
                      </div>
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </div>
          </div>

          <div className="package-selector" style={{ left: 0 }}>
            <p className="package-selector-title">Package</p>
            <ToggleButtonGroup
              type="radio"
              name="packages"
              size="sm"
              orientation="vertical"
              defaultValue={0}
              exclusive={true}
              value={selectedPackage}
              onChange={onPackageSelected}
            >
              {packageData?.packages?.map((pkg, index) => (
                <ToggleButton
                  key={index}
                  id={`package-radio-${index}`}
                  className="package-radio"
                  variant="outline-light"
                  name="package-radio"
                  value={index}
                  checked={selectedPackage === index}
                >
                  {pkg.title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>

          <div
            className="package-selector"
            style={{
              left: 0,
              right: 0,
              alignItems: "center",
              bottom: 0,
              top: "auto",
            }}
          >
            <p className="package-selector-title">View</p>
            <ToggleButtonGroup
              type="radio"
              name="pictures"
              size="sm"
              defaultValue={0}
              exclusive={true}
              value={selectedPicture}
              onChange={onPictureSelected}
            >
              {packageData?.packages
                ?.at(selectedPackage)
                ?.rooms?.at(selectedRoom)
                ?.pictures?.map((picture, index) => (
                  <ToggleButton
                    key={index}
                    id={`package-room-picture-radio-${index}`}
                    variant="outline-light"
                    name="picture-radio"
                    value={index}
                    checked={selectedPicture === index}
                  >
                    {picture.title}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageSelector;
