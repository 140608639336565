import "./LuxuryInEveryCorner.css";
import { useEffect, useState } from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import bgDesignImg from "../../asset/images/landing_screen_bg.jpg";
import PanoramaFishEyeOutlinedIcon from "@mui/icons-material/PanoramaFishEyeOutlined";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import RoundedCornerOutlinedIcon from "@mui/icons-material/RoundedCornerOutlined";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";

import FsLightbox from "fslightbox-react";

function LuxuryInEveryCorner() {
  const [luxuryGalleryController, setLuxuryGalleryController] = useState({
    toggler: false,
    slide: 1,
  });

  function openPackagesGalleryOnSlide(number) {
    setLuxuryGalleryController({
      toggler: !luxuryGalleryController.toggler,
      slide: number,
    });
  }

  function onExploreMore(path) {
    const element = document.getElementById(path);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }

  const interiorDesignImg1 =
    "https://elitenooks.com/uploads/pictures/static/luxury-in-every-corner/luxury-interior-1.jpg";
  const interiorDesignImg2 =
    "https://elitenooks.com/uploads/pictures/static/luxury-in-every-corner/luxury-interior-2.jpg";
  const interiorDesignImg3 =
    "https://elitenooks.com/uploads/pictures/static/luxury-in-every-corner/luxury-interior-3.jpg";
  const interiorDesignImg4 =
    "https://elitenooks.com/uploads/pictures/static/luxury-in-every-corner/luxury-interior-4.jpg";

  return (
    <>
      <FsLightbox
        sources={[
          interiorDesignImg1,
          interiorDesignImg2,
          interiorDesignImg3,
          interiorDesignImg4,
        ]}
        // types={projectData?.visuals?.map((image, index) => image.type)}
        toggler={luxuryGalleryController.toggler}
        slide={luxuryGalleryController.slide}
        exitFullscreenOnClose={true}
        // key={projectData?.views}
      />
      <div className="we-do-everything-parent-container">
        <div
          className="we-do-everything-bg-image-container"
          style={{ backgroundImage: `url(${bgDesignImg})` }}
        ></div>
        <div className="we-do-everything-inner-container max-inner-width">
          <p className="we-do-everything-section-title brand-font-primary">
            Luxury in every corner
          </p>
          <p className="we-do-everything-section-subtitle">
            We believe everything in your home deserve a touch of it's own.
            <br></br>We bring our designs in a way, where every corner speaks
            luxury.
          </p>
          <a
            onClick={() => onExploreMore("photogenic-interiors-view")}
            className="we-do-everything-to-about-us-cta"
          >
            <p>Learn more</p>
            <ArrowForwardOutlinedIcon className="we-do-everything-to-about-us-cta-icon" />
          </a>
          <div className="we-do-everything-highlights-grid-view">
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <ChangeHistoryOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
            <div
              className="we-do-everything-highlight-grid-item blank-grid"
              style={{
                backgroundImage: "url(" + interiorDesignImg1 + ")",
              }}
              onClick={() => openPackagesGalleryOnSlide(1)}
            ></div>
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <RoundedCornerOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
            <div className="we-do-everything-highlight-grid-item"></div>
            <div
              className="we-do-everything-highlight-grid-item blank-grid"
              style={{
                backgroundImage: "url(" + interiorDesignImg2 + ")",
              }}
              onClick={() => openPackagesGalleryOnSlide(2)}
            ></div>
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <CropFreeOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <ChangeHistoryOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
            <div
              className="we-do-everything-highlight-grid-item blank-grid"
              style={{
                backgroundImage: "url(" + interiorDesignImg3 + ")",
              }}
              onClick={() => openPackagesGalleryOnSlide(3)}
            ></div>
            <div className="we-do-everything-highlight-grid-item ">
              <div className="we-do-everything-highlight-icon-container"></div>
            </div>
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <PanoramaFishEyeOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
            <div
              className="we-do-everything-highlight-grid-item blank-grid"
              style={{
                backgroundImage: "url(" + interiorDesignImg4 + ")",
              }}
              onClick={() => openPackagesGalleryOnSlide(4)}
            ></div>
            <div className="we-do-everything-highlight-grid-item">
              <div className="we-do-everything-highlight-icon-container">
                <RemoveOutlinedIcon className="we-do-everything-highlight-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LuxuryInEveryCorner;
