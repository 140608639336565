import "./WorkProcess.css";
import planningImg from "../../asset/images/within-45-days/creative-architect.jpg";
import interiorDesign from "../../asset/images/within-45-days/interior-design.jpg";
import interiorWorkersWorking from "../../asset/images/within-45-days/designer-working.jpg";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

function WorkProcess() {
  return (
    <div className="work-process-parent-container">
      <div className="work-process-inner-container max-inner-width">
        {/* <p className="work-process-section-title">Get it all!</p> */}
        {/* <a className="work-process-to-about-us-cta" href="#">
            <p>
              Planning, interior design, styling and much more. We got
              everything covered!
            </p>
            <ArrowForwardOutlinedIcon className="work-process-to-about-us-cta-icon" />
          </a> */}
        <div className="work-process-main-content">
          {/* <div className="work-process-highlights-grid-view">
            <p className="work-process-section-title brand-font-primary">
              Yes. Everything<br></br>within 45 days!
            </p>
            <div className="common-primary-divider"></div>
            <div
              className="work-process-highlight-grid-item"
              data-aos="fade-left"
            >
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Planning</p>
                <p className="work-process-highlight-body">
                  Explore our Interior Design Planning services to transform
                  your space into a harmonious blend of aesthetics and
                  functionality.
                </p>
              </div>
            </div>
            <div
              className="work-process-highlight-grid-item"
              data-aos="fade-left"
            >
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Execution</p>
                <p className="work-process-highlight-body">
                  Our experts offer tailored solutions to realize your vision,
                  from conceptualization to execution.
                </p>
              </div>
            </div>
            <div
              className="work-process-highlight-grid-item"
              data-aos="fade-left"
            >
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Styling</p>
                <p className="work-process-highlight-body">
                  Let your home reflect your personality and style with our
                  expert guidance and design solutions.
                </p>
              </div>
            </div>
          </div> */}
          <div className="landing-screen-text-container animate__animated animate__fadeInRight">
            <p className="landing-screen-section-title">
              Your<br></br>
              <b>walk-in home</b>
              <br></br>is waiting<br></br>for you
            </p>
            <p className="landing-screen-section-subtitle">
              From interior to styling, we got you covered.
            </p>
            <List dense={true} style={{ marginBottom: "8px" }}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CheckOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Completely Styled" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CheckOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="45 days handover" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CheckOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inexpensive Luxury" />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
          <div className="work-process-pictures" data-aos="fade-right">
            <div
              className="work-process-picture"
              style={{
                backgroundImage: `url(${interiorDesign})`,
              }}
            ></div>
            <div
              className="work-process-picture"
              style={{
                backgroundImage: `url(${planningImg})`,
              }}
            ></div>
            <div
              className="work-process-picture"
              style={{
                backgroundImage: `url(${interiorWorkersWorking})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkProcess;
