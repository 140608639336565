import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import "./App.css";
import { Suspense, useEffect, useState } from "react";
import Home from "./views/home/Home";
import UnknownPage from "./views/unknown/404";
import PackageSelector from "./views/package_selector/PackageSelector";
import Project from "./views/project/Project";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Projects from "./views/projects/Projects";
import Contact from "./views/contact/Contact";
import routes from "./const/routes";
import PackageSizeSelector from "./views/package_size_selector/PackageSizeSelector";
import DotsPatternBackground from "./asset/images/dots_pattern_2.png";
import BeforeAfterElitenooks from "./components/BeforeAfterElitenooks/BeforeAfterElitenooks";
import PrivacyPolicy from "./views/legal/policies/PrivacyPolicy";
import About from "./views/about/About";
import Fab from '@mui/material/Fab';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import ContactRequestResult from "./views/contact_request_result/ContactRequestResult";


const theme = createTheme({
  palette: {
    primary: {
      light: "#B25BEB",
      main: "#491539",
      dark: "#1b0012",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e5ca8d",
      main: "#b79e6a",
      dark: "#BCA163",
      contrastText: "#000",
    },
    light: {
      light: "#fff",
      main: "#fff",
      dark: "#ddd",
      contrastText: "#000",
    },
  },
});

function App() {
  const [loading, setLoading] = useState(true);
  const [contactFormLoading, setContactFormLoading] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onInit();
  }, []);

  function onToggleContactForm() {
    setShowContactForm(!showContactForm);
  }

  async function onInit() {
    try {
      setLoading(true);
      await onCreateGuestUser();
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  async function onCreateGuestUser() {
    // Implement this
  }

  function onContactRequestUploaded() {
    navigate(routes.contactRequestResult);
    window.scrollTo(0, 0);
  }

  function onContactFormSubmit(event) {
    setContactFormLoading(true);
    event.preventDefault();
    const apiUrl =
      "https://elitenooks.com/api/website/process_contact_form_plus.php";

    const form = document.forms.FloatingContactForm;
    const formData = new FormData(form);
    formData.append('from_url', window.location.href);

    setContactFormLoading(true);

    axios({
      method: "post",
      url: apiUrl,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        form.reset();
        setContactFormLoading(false);
        setShowContactForm(false);
        onContactRequestUploaded();
      })
      .catch(function (response) {
        console.log(response);
        setContactFormLoading(false);
        window.alert("Something went wrong, please try again.");
      });
  }

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Header />
          <div
            className="content-body"
            style={{ backgroundImage: `url(${DotsPatternBackground})` }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Routes>
                {/* <Route path={routes.home} element={<BeforeAfterElitenooks />} /> */}
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.about} element={<About />} />
                <Route
                  path={routes.packages}
                  element={<PackageSizeSelector />}
                />
                <Route
                  path={routes.packagesExplore}
                  element={<PackageSelector />}
                />
                <Route path={routes.projects} element={<Projects />} />
                <Route path={routes.project} element={<Project />} />
                <Route path={routes.contact} element={<Contact />} />
                <Route path={routes.contactRequestResult} element={<ContactRequestResult />} />
                <Route
                  path={routes.legal.privacyPolicy}
                  element={<PrivacyPolicy />}
                />
                <Route path={routes.unknown} element={<UnknownPage />} />
              </Routes>
            )}
          </div>
          <Footer />

          <div className="floating-contact-form-container">
            {showContactForm && <div className="floating-contact-form-inner-container">
            <form id="FloatingContactForm" onSubmit={onContactFormSubmit}>
              <div className="floating-contact-form-header">
                <p>Contact</p>
                <IconButton aria-label="delete" onClick={onToggleContactForm}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="floating-contact-form-item">
                <TextField
                      id="contact-details-item-name"
                      className="floating-contact-form-input"
                      label="Full name"
                      variant="outlined"
                      type="text"
                      name="name"
                      required
                    />
                    </div>
                    <div className="floating-contact-form-item">
                    <TextField
                    id="contact-details-item-phone"
                    className="floating-contact-form-input"
                    label="Phone"
                    variant="outlined"
                    type="phone"
                    name="phone"
                    required
                  />
                  </div>
                  <div className="contact-details-acknowledge-input-container">
                    <input
                      id="contact-detail-whatsapp-acknowledge-checkbox"
                      type="checkbox"
                      name="whatsapp_connect"
                      defaultChecked
                    />
                    <label htmlFor="contact-detail-whatsapp-acknowledge-checkbox">
                      Connect on WhatsApp
                    </label>
                  </div>
                  <div className="floating-contact-form-item">
                  <TextField
                    id="contact-details-item-email"
                    className="floating-contact-form-input"
                    label="Email"
                    variant="outlined"
                    name="email"
                    type="email"
                  />
                  </div>
                  <div className="contact-details-acknowledge-input-container">
                    <input
                      id="contact-detail-email-acknowledge-checkbox"
                      type="checkbox"
                      name="email_connect"
                      defaultChecked
                    />
                    <label htmlFor="contact-detail-email-acknowledge-checkbox">
                      Receive email updates
                    </label>
                  </div>
                  <div className="floating-contact-form-item">
                  <TextareaAutosize
                    minRows={4}
                    maxRows={10}
                    aria-label="maximum height"
                    placeholder=" Express your thoughts here..."
                    defaultValue=""
                    name="message"
                    className="floating-contact-form-input"
                  />
                  </div>
                  <p>
                  By submitting this form, I agree with the{" "}
                  <a href={routes.legal.privacyPolicy}>Privacy Policy</a>.
                </p>
                <LoadingButton
                  className="submit_button floating-contact-form-input"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  loading={contactFormLoading}
                >
                  Submit
                </LoadingButton>
              </form>
            </div>}
            <Fab color="primary" aria-label="contact" className="floating-contact-button" onClick={onToggleContactForm}>
              <MessageIcon />
            </Fab>
          </div>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
