import "./WalkInHomes.css";
import cameraViewfinder from "../../asset/images/phone-camera-frame.png";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import interiorDesignImg from "../../asset/images/landing_screen_bg.jpg";

function WalkInHomes() {
  function onExploreMore(path) {
    const element = document.getElementById(path);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }

  return (
    <div
      id="photogenic-interiors-view"
      className="walk-in-homes-parent-container"
    >
      <div className="walk-in-homes-inner-container max-inner-width">
        <div className="walk-in-homes-section-title">
          <p className="walk-in-homes-section-subtitle">
            {/* Nice Designs are not enough, you need */}
          </p>
          <p className="walk-in-homes-section-title-text brand-font-primary">
            Photogenic interiors<br></br>that you'll love!
          </p>
          <p className="walk-in-homes-section-body">
            We take care of every tiny detail.<br></br>So no matter from what
            angle you look at your home,<br></br>everything will look just
            awesome!
            {/* Right from interior to styling. We take care of everything! */}
          </p>
          <a
            onClick={() => onExploreMore("projects-view")}
            className="walk-in-homes-to-about-us-cta"
          >
            <p>Learn more</p>
            <ArrowForwardOutlinedIcon className="walk-in-homes-to-about-us-cta-icon" />
          </a>
        </div>
        <div className="walk-in-homes-window-parallax-container">
          <div
            className="walk-in-homes-window-parallax-back"
            style={{
              backgroundImage: `url(${interiorDesignImg})`,
            }}
          ></div>
          <div
            className="walk-in-homes-window-parallax-front"
            style={{
              backgroundImage: `url(${cameraViewfinder})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default WalkInHomes;
