import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Project.css";
import { Autoplay, A11y, EffectFade, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import FsLightbox from "fslightbox-react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import Loader from "../../components/Loader/Loader";
import MessageBox from "../../components/MessageBox/MessageBox";
import CtaConnectWithUs from "../../components/CtaConnectWithUs/CtaConnectWithUs";
import { Button } from "@mui/material";
import { RWebShare } from "react-web-share";
import SimilarProjects from "../../components/SimilarProjects/SimilarProjects";

function Project() {
  const { id } = useParams();
  const [viewsGalleryController, setViewsGalleryController] = useState({
    toggler: false,
    slide: 1,
  });
  const [visualsGalleryController, setVisualsGalleryController] = useState({
    toggler: false,
    slide: 1,
  });
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState();
  const [similarProjectsData, setSimilarProjectsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#project_pictures",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    // lightbox.listen("gettingData", function (index, item) {
    //   if (item.w < 1 || item.h < 1) {
    //     var img = new Image();
    //     img.onload = function () {
    //       item.w = this.width;
    //       item.h = this.height;
    //       lightbox.updateSize(true);
    //     };
    //     img.src = item.src;
    //   }
    // });

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  useEffect(() => {
    fetchProjectDetails();
  }, [id]);

  useEffect(() => {
    if (projectData) {
      fetchSimilarProjectsDetails();
    }
  }, [projectData]);

  async function fetchProjectDetails() {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://elitenooks.com/api/get_projects.php?project_id=" + id,
        {
          validateStatus: function (_) {
            return true;
          },
        }
      );
      if (response.status === 200) {
        setProjectData(response.data);
        fetchSimilarProjectsDetails();
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
    } finally {
      // setLoading(false);
    }
  }

  async function fetchSimilarProjectsDetails() {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://elitenooks.com/api/get_similar_projects.php?id=${id}&package_id=${projectData.package.id}&size=${projectData.size}&location=${projectData.located_at}`
      );
      if (response.status === 200) {
        setSimilarProjectsData(response.data?.projects);
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }

  function onExploreMore(path) {
    const element =
      document.getElementById(path) ??
      document.getElementById("similar-projects-view");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }

  function onOpenProject(id) {
    navigate(`/project/${id}`);
  }

  function openVisualsGalleryOnSlide(number) {
    setVisualsGalleryController({
      toggler: !visualsGalleryController.toggler,
      slide: number,
    });
  }

  function openViewsGalleryOnSlide(number) {
    setViewsGalleryController({
      toggler: !viewsGalleryController.toggler,
      slide: number,
    });
  }

  function onShareProject() {
    // Do nothing
  }

  if (loading) return <Loader />;

  return (
    <>
      <FsLightbox
        sources={projectData?.visuals?.map((image, index) => image.link)}
        // types={projectData?.visuals?.map((image, index) => image.type)}
        toggler={visualsGalleryController.toggler}
        slide={visualsGalleryController.slide}
        exitFullscreenOnClose={true}
        // key={projectData?.views}
      />
      <FsLightbox
        sources={projectData?.views?.map((image, index) => image.link)}
        toggler={viewsGalleryController.toggler}
        exitFullscreenOnClose={true}
        slide={viewsGalleryController.slide}
        // key={projectData?.views}
      />
      {projectData ? (
        <div className="project-parent-container">
          {/* Share Option */}
          {/* <div className="project-share-button" href="#">
        <p>Share</p>
      </div> */}
          <div className="project-intro-parent-container">
            <div className="project-intro-bg-container">
              {/* <div className="project-details-cta-for-callback-circles-bg-container">
            <div className="circles-bg-large"></div>
            <div className="circles-bg-med"></div>
            <div className="circles-bg-small"></div>
          </div> */}
            </div>
            {/* Project main details */}
            <div className="project-summary-container max-inner-width-reduced">
              <div className="project-details-container animate__animated animate__fadeInLeft">
                {/* Project Title, Subtitle */}
                <p className="project-title">{projectData?.title}</p>
                {/* Home Size (1bhk, 2bhk, 3bhk + | flat, row-house, bungalow) */}
                <p className="project-time">
                  Completed in {projectData?.completed_at}
                </p>
                {/* Location (City, Area, etc) */}
                <div className="project-location-container">
                  <LocationOnOutlinedIcon className="project-details-icon" />
                  <p>{projectData?.located_at}</p>
                </div>
                <RWebShare
                  data={{
                    text: "Check out this amazing project by Elitenooks",
                    url: window.location.href,
                    title: projectData?.title ?? "Elitenooks",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <Button
                    className="project-share-button"
                    variant="outlined"
                    size="small"
                    startIcon={<ShareOutlinedIcon />}
                    onClick={onShareProject}
                  >
                    Share
                  </Button>
                </RWebShare>
              </div>
              <div className="project-key-picture-parent-container animate__animated animate__fadeInRight">
                {/* Key Picture (or slideshow) */}
                <div className="project-key-picture-bg-color-box"></div>
                <Swiper
                  className="project-key-picture-container"
                  effect="fade"
                  modules={[Autoplay, A11y, EffectFade]}
                  spaceBetween={50}
                  slidesPerView={1}
                  //   initialSlide={1}
                  parallax={true}
                  centeredSlides={true}
                  speed={1000}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  //   navigation={true}
                >
                  <SwiperSlide>
                    <div
                      className="project-key-picture"
                      style={{
                        backgroundImage: `url(${projectData?.key_picture})`,
                      }}
                    ></div>
                  </SwiperSlide>
                  {projectData?.visuals?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="project-key-picture"
                        style={{
                          backgroundImage: `url(${item.link})`,
                        }}
                      ></div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {/* Project other details */}
            <div className="project-other-details-container animate__animated animate__fadeInUp">
              <div className="project-other-details-item">
                <WidgetsOutlinedIcon className="project-other-details-icon" />
                <p>{projectData?.package?.title} Package</p>
              </div>
              <div className="project-other-details-item">
                <MeetingRoomOutlinedIcon className="project-other-details-icon" />
                <p>{projectData?.size}</p>
              </div>
              <div className="project-other-details-item">
                <AspectRatioOutlinedIcon className="project-other-details-icon" />
                <p>{projectData?.carpet_size}</p>
              </div>
              <div
                className="project-other-details-item"
                onClick={() => onExploreMore("project-visuals")}
              >
                <ExpandMoreOutlinedIcon className="project-other-details-icon" />
                <p>Explore more</p>
              </div>
            </div>
          </div>
          {/* Room Details (Pictures) */}
          <div className="project-room-details-parent-container">
            <div className="project-room-details-inner-container">
              {projectData?.visuals?.at(0) && (
                <div
                  id="project-visuals"
                  className="project-room-details-picture-gallery max-inner-width"
                >
                  <div className="section-title">
                    <p className="section-title-text brand-font-primary">
                      Visuals gallery
                    </p>
                    <p className="section-subtitle-text">
                      Take a visual experience of this project
                    </p>
                  </div>
                  {projectData?.visuals.map((image, index) => (
                    <div
                      className="project-room-details-picture"
                      key={index}
                      onClick={() => openVisualsGalleryOnSlide(index + 1)}
                    >
                      {image.type === "picture" && (
                        <img src={image.link} alt={index} loading="lazy" />
                      )}
                      {image.type === "video" && <div>Video</div>}
                    </div>
                  ))}
                  <div
                    className="project-room-details-picture explore-more-button"
                    onClick={() => onExploreMore("project-views")}
                  >
                    <p>Explore more</p>
                    <ArrowDownwardOutlinedIcon className="explore-more-icon" />
                  </div>
                </div>
              )}
              <CtaConnectWithUs />
              {projectData?.views?.at(0) && (
                <div
                  id="project-views"
                  className="project-room-details-picture-gallery project-views max-inner-width"
                >
                  <div className="section-title">
                    <p className="section-title-text brand-font-primary">
                      Project views
                    </p>
                    <p className="section-subtitle-text">
                      Pre-completion 3D views of this project
                    </p>
                  </div>
                  {projectData?.views?.map((image, index) => (
                    <div
                      className="project-room-details-picture"
                      key={index}
                      onClick={() => openViewsGalleryOnSlide(index + 1)}
                    >
                      {image.type === "picture" && (
                        <img src={image.link} alt={index} loading="lazy" />
                      )}
                      {image.type === "video" && <div>Video</div>}
                    </div>
                  ))}
                  <div
                    className="project-room-details-picture explore-more-button"
                    onClick={() => onExploreMore("similar-projects")}
                  >
                    <p>Explore more</p>
                    <ArrowDownwardOutlinedIcon className="explore-more-icon" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* similar projects slideshow */}
          <SimilarProjects />
          {/* Links to other pages */}
        </div>
      ) : (
        <MessageBox
          title="Oops!"
          message="This project is not available at this moment, please try again later"
        />
      )}
    </>
  );
}

export default Project;
