import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PuffLoader } from "react-spinners";

function Loader() {
  const navigate = useNavigate();

  return (
    <>
      <div className="Page Loader">
        <PuffLoader className="global_loader" color="#5f1247" size={100} />
        {/* <p className="Title Primary-text Body-text">Please Wait</p>
        <p className="Subtitle Body-text-small">Loading the content</p> */}
      </div>
    </>
  );
}

export default Loader;
