import "./Footer.css";
import logo_light from "../../asset/brand/logo_light.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import routes from "../../const/routes";
import contactDetails from "../../const/contact_details";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner-container max-inner-width">
        <div className="footer-grid-view">
          <div className="footer-grid-item">
            <div className="footer-logo">
              <img src={logo_light} className="brand-logo" alt="logo" />
            </div>
          </div>
          <div className="footer-grid-item">
            <p className="footer-grid-item-label brand-font-primary">Explore</p>
            <div className="footer-contact-links-container">
              <a href={routes.home}>
                <p>Home</p>
              </a>
              <a href={routes.projects}>
                <p>Projects</p>
              </a>
              <a
                href={routes.packages
                  .replace("/:type?", "")
                  .replace("/:package_id?", "")}
              >
                <p>Packages</p>
              </a>
              <a
                href={routes.legal.privacyPolicy}
              >
                <p>Privacy Policy</p>
              </a>
            </div>
          </div>

          <div className="footer-grid-item">
            <p className="footer-grid-item-label brand-font-primary">
              Get in touch
            </p>
            <div className="footer-contact-links-container">
              <a
                rel="noreferrer"
                href={`tel:${contactDetails.phone.replace(" ", "")}`}
                target="_blank"
              >
                <CallOutlinedIcon />
                <p>{contactDetails.phone}</p>
              </a>
              <a
                rel="noreferrer"
                href={`mailto:${contactDetails.email}`}
                target="_blank"
              >
                <EmailOutlinedIcon />
                <p>{contactDetails.email}</p>
              </a>
              <a
                rel="noreferrer"
                href={contactDetails.mapLocation}
                target="_blank"
              >
                <LocationOnOutlinedIcon />
                <p>{contactDetails.address}</p>
              </a>
            </div>
          </div>

          <div className="footer-grid-item">
            <p className="footer-grid-item-label brand-font-primary">Connect</p>
            <div className="footer-social-media-links-container">
              <a
                rel="noreferrer"
                href={contactDetails.socialMedia.instagram}
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                rel="noreferrer"
                href={contactDetails.socialMedia.facebook}
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                rel="noreferrer"
                href={contactDetails.socialMedia.linkedin}
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              {/* <a href={contactDetails.socialMedia.twitter} target="_blank">
                <XIcon />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-bar">
        <p className="Body-text-small Copyright-text">
          Copyright 2024 - 2025 © Elitenooks - All rights reserved.
        </p>
        <a
          rel="noreferrer"
          className="Body-text-small Copyright-text"
          href="https://www.conceptures.com/"
          target="_blank"
        >
          designed by Conceptures
        </a>
      </div>
    </div>
  );
}
export default Footer;
