import "./Partners.css";

import axios from "axios";

import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";

function Partners() {
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState({});

  useEffect(() => {
    fetchTeamDetails();
  }, []);

  async function fetchTeamDetails() {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://elitenooks.com/api/get_team.php",
        {
          validateStatus: function (_) {
            return true;
          },
        }
      );
      if (response.status === 200) {
        setTeamData(response.data);
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <Loader />;

  return (
    <>
      <div className="partners-parent-container">
        <div className="partners-inner-container">
          <div className="partners-partners-container">
            <div className="partners-partners-container-inner partners-body-section max-inner-width">
              {/* <p className="partners-partners-section-title brand-font-primary">We Work with the Best Partners</p>
              <p>
              At Elitenooks, we collaborate with only the most esteemed partners in the industry. Their commitment to quality, innovation, and excellence aligns with ours, ensuring superior craftsmanship and exceptional service in every project.
              </p> */}

              <div className="section-title about-body-section">
                <p className="section-subtitle"></p>
                <p className="section-title-text brand-font-primary">
                We Work with the Best Partners
                </p>
                <p className="section-body">
                At Elitenooks, we collaborate with only the most esteemed partners in the industry. Their commitment to quality, innovation, and excellence aligns with ours, ensuring superior craftsmanship and exceptional service in every project.
                </p>
              </div>


              <div className="partners-partners-grid-view">
                {
                  teamData?.partners?.map((profile) => (
                    <div className="partners-partners-grid-item">
                      <div className="partners-partners-grid-item-picture" style={{
                          backgroundImage: `url(${profile.picture})`,
                        }}></div>
                      <div className="partners-partners-grid-item-details">
                        <p className="partners-partners-grid-item-details-title">{profile.name}</p>
                        <p className="partners-partners-grid-item-details-subtitle">{profile.designation}</p>
                        {profile.link && <Button variant="outlined" className="partners-partners-grid-item-details-cta" href={profile.link} target="_blank">Know More</Button>}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;
