import React, { useEffect, useState } from "react";
import "./Home.css";
import Landing from "../../components/Landing/Landing";
import WalkInHomes from "../../components/WalkInHomes/WalkInHomes";
import LuxuryInEveryCorner from "../../components/LuxuryInEveryCorner/LuxuryInEveryCorner";
import Packages from "../../components/Packages/Packages";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import WorkProcess from "../../components/WorkProcess/WorkProcess";
import PreLoader from "../../components/PreLoader/PreLoader";
import FeaturedProjects from "../../components/FeaturedProjects/FeaturedProjects";
import BeforeAfterElitenooks from "../../components/BeforeAfterElitenooks/BeforeAfterElitenooks";
import Partners from "../partners/Partners";

function Home() {
  const [loading, setLoading] = useState(true);

  function componentDidMount() {
    setLoading(false);
  }

  useEffect(() => {
    componentDidMount();
  }, []);

  return (
    <>
      {loading && <PreLoader />}
      <Landing />
      <BeforeAfterElitenooks />
      <WhyChooseUs />
      <Partners/>
      <Packages />
      <WorkProcess />
      <LuxuryInEveryCorner />
      <WalkInHomes />
      <FeaturedProjects />
    </>
  );
}

export default Home;
