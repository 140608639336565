const contactDetails = {
  phone: "1800 209 8666",
  whatsApp: "7030718166",
  email: "info@elitenooks.com",
  address:
    "Block No 52, 1194/9, Shubhashri Apartment Ghole Road, Shivajinagar, Pune 411005",
  addressShort: "Ghole Road, Shivajinagar, Pune 411005",
  mapLocation: "https://maps.app.goo.gl/oZsKwQtqc2FdAVFk6",
  socialMedia: {
    instagram: "https://www.instagram.com/elitenooks/",
    facebook: "https://www.facebook.com/people/Elite-Nooks/61559858486708/",
    linkedin: "https://www.linkedin.com/company/elitenooks/",
    youtube: "https://elitenooks.com/",
    twitter: "https://elitenooks.com/",
  },
};

export default contactDetails;
