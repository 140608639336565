import "./Contact.css";

import axios from "axios";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import XIcon from "@mui/icons-material/X";

import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

import contactDetails from "../../const/contact_details";
import WorkWithUs from "../../components/WorkWIthUs/WorkWithUs";
import routes from "../../const/routes";
import { useNavigate } from "react-router-dom";

function Contact() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  function onContactRequestUploaded() {
    navigate(routes.contactRequestResult);
    window.scrollTo(0, 0);
  }

  function onContactFormSubmit(event) {
    event.preventDefault();
    const apiUrl =
      "https://elitenooks.com/api/website/process_contact_form.php";

    const form = document.forms.ContactForm;
    const formData = new FormData(form);

    setLoading(true);

    axios({
      method: "post",
      url: apiUrl,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        form.reset();
        setLoading(false);
        onContactRequestUploaded();
      })
      .catch(function (response) {
        //handle error
        setLoading(false);
        window.alert("Something went wrong, please try again.");
      });
  }

  return (
    <>
      <div className="contact-parent-container">
        <div className="contact-inner-container max-inner-width">
          <div className="section-title">
            <p className="section-subtitle">
              {/* Nice Designs are not enough, you need */}
            </p>
            <p className="section-title-text brand-font-primary">
              Let's connect and discuss<br></br>your home interior today!
            </p>
            <p className="section-body">
              We take care of every tiny detail. So no matter from what angle
              you look at your home, everything will look just awesome!
              {/* Right from interior to styling. We take care of everything! */}
            </p>
          </div>
          <div className="contact-details-container max-inner-width animate__animated animate__fadeInUp">
            <a
              rel="noreferrer"
              className="contact-detail"
              href={contactDetails.mapLocation}
              target="_blank"
            >
              <FmdGoodOutlinedIcon className="contact-detail-icon" />
              <div className="contact-details-text-container">
                <p className="contact-detail-label">Office</p>
                <p className="contact-detail-title">
                  {contactDetails.addressShortapLocation}
                </p>
                <p className="contact-detail-subtitle">
                  Mon - Sat | 9AM to 6PM
                </p>
              </div>
            </a>
            <a
              rel="noreferrer"
              className="contact-detail"
              href={`tel:${contactDetails.phone.replace(" ", "")}`}
              target="_blank"
            >
              <CallOutlinedIcon className="contact-detail-icon" />
              <div className="contact-details-text-container">
                <p className="contact-detail-label">Call</p>
                <p className="contact-detail-title">{contactDetails.phone}</p>
                <p className="contact-detail-subtitle">
                  Mon - Sat | 9AM to 6PM
                </p>
              </div>
            </a>
            <a
              rel="noreferrer"
              className="contact-detail"
              href={`https://wa.me/91${contactDetails.whatsApp.replace(
                " ",
                ""
              )}`}
              target="_blank"
            >
              <WhatsAppIcon className="contact-detail-icon" />
              <div className="contact-details-text-container">
                <p className="contact-detail-label">WhatsApp</p>
                <p className="contact-detail-title">
                  {contactDetails.whatsApp}
                </p>
                {/* <p className="contact-detail-subtitle">Share your thoughts</p> */}
              </div>
            </a>
            <a
              rel="noreferrer"
              className="contact-detail"
              href={`mailto:${contactDetails.email}`}
              target="_blank"
            >
              <DraftsOutlinedIcon className="contact-detail-icon" />
              <div className="contact-details-text-container">
                <p className="contact-detail-label">Email</p>
                <p className="contact-detail-title">{contactDetails.email}</p>
                {/* <p className="contact-detail-subtitle">Write to us</p> */}
              </div>
            </a>
          </div>

          <div className="contact-form-and-details-parent-container">
            <div className="contact-details-form">
              <form id="ContactForm" onSubmit={onContactFormSubmit}>
                <div className="contact-details-form-item">
                  <TextField
                    id="contact-details-item-name"
                    className="contact-form-input"
                    label="Full name"
                    variant="outlined"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div className="contact-details-form-item">
                  <TextField
                    id="contact-details-item-phone"
                    className="contact-form-input"
                    label="Phone"
                    variant="outlined"
                    type="phone"
                    name="phone"
                    required
                  />
                  <div className="contact-details-acknowledge-input-container">
                    <input
                      id="contact-detail-whatsapp-acknowledge-checkbox"
                      type="checkbox"
                      name="whatsapp_connect"
                      defaultChecked
                    />
                    <label htmlFor="contact-detail-whatsapp-acknowledge-checkbox">
                      I would like to connect on WhatsApp
                    </label>
                  </div>
                </div>
                <div className="contact-details-form-item">
                  <TextField
                    id="contact-details-item-email"
                    className="contact-form-input"
                    label="Email"
                    variant="outlined"
                    name="email"
                    type="email"
                  />
                  <div className="contact-details-acknowledge-input-container">
                    <input
                      id="contact-detail-email-acknowledge-checkbox"
                      type="checkbox"
                      name="email_connect"
                      defaultChecked
                    />
                    <label htmlFor="contact-detail-email-acknowledge-checkbox">
                      I would like to receive email updates
                    </label>
                  </div>
                </div>
                <div className="contact-details-form-item">
                  <TextareaAutosize
                    minRows={4}
                    maxRows={10}
                    aria-label="maximum height"
                    placeholder=" Express your thoughts here..."
                    defaultValue=""
                    name="message"
                  />
                </div>
                <p>
                  By submitting this form, I agree with the{" "}
                  <a href={routes.legal.privacyPolicy}>Privacy Policy</a>.
                </p>
                <LoadingButton
                  className="submit_button"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  loading={loading}
                >
                  {loading ? "" : "Submit"}
                </LoadingButton>
              </form>
            </div>
            <div className="contact-get-in-touch-container">
              <p className="contact-get-in-touch-title">Get in touch</p>
              <p className="contact-get-in-touch-subtitle">
                Get in touch with us for some interesting updates!
              </p>
              <div className="social-media-handles-container max-inner-width">
                <a
                  rel="noreferrer"
                  className="social-media-handle"
                  href={contactDetails.socialMedia.instagram}
                  target="_blank"
                >
                  <InstagramIcon className="social-media-handle-icon" />
                </a>
                <a
                  rel="noreferrer"
                  className="social-media-handle"
                  href={contactDetails.socialMedia.facebook}
                  target="_blank"
                >
                  <FacebookIcon className="social-media-handle-icon" />
                </a>
                <a
                  rel="noreferrer"
                  className="social-media-handle"
                  href={contactDetails.socialMedia.linkedin}
                  target="_blank"
                >
                  <LinkedInIcon className="social-media-handle-icon" />
                </a>
                {/* <a
                  rel="noreferrer"
                  className="social-media-handle"
                  href={contactDetails.socialMedia.twitter}
                  target="_blank"
                >
                  <XIcon className="social-media-handle-icon" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkWithUs />
    </>
  );
}

export default Contact;
