import "./Landing.css";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";

import ElitenooksLogoAnimated from "../../asset/brand/logo_dark_animated.svg";

import MarbleBackground from "../../asset/images/marble-texture-1.png";
import DotsPatternBackground from "../../asset/images/dots_pattern_2.png";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import contactDetails from "../../const/contact_details";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "animate.css";

function Landing() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  function onExploreMore(path) {
    const element = document.getElementById(path);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }

  return (
    <div className="landing-screen-container">
      {/* <div
        className="landing-screen-bg-pattern"
        style={{ backgroundImage: `url(${DotsPatternBackground})` }}
      ></div> */}
      {/* <div>
        <img src={ElitenooksLogoAnimated} />
      </div> */}
      <div className="landing-screen-inner-container">
        {/* <div className="landing-screen-bg-video-background-1"></div> */}
        <div className="landing-screen-text-container animate__animated animate__fadeInRight">
          {/* <p className="landing-screen-section-title">
            Your<br></br>
            <b>walk-in home</b>
            <br></br>is waiting<br></br>for you
          </p>
          <p className="landing-screen-section-subtitle">
            From interior to styling, we got you covered.
          </p>
          <List dense={true} style={{ marginBottom: "8px" }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CheckOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Completely Styled" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CheckOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="45 days handover" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CheckOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Inexpensive Luxury" />
              </ListItemButton>
            </ListItem>
          </List> */}
          <div className="work-process-highlights-grid-view">
            <p className="work-process-section-title brand-font-primary">
              Yes. Everything<br></br>within 45 days!
            </p>
            <div className="common-primary-divider"></div>
            <div className="work-process-highlight-grid-item">
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Planning</p>
                <p className="work-process-highlight-body">
                  Explore our Interior Design Planning services to transform
                  your space into a harmonious blend of aesthetics and
                  functionality.
                </p>
              </div>
            </div>
            <div className="work-process-highlight-grid-item">
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Execution</p>
                <p className="work-process-highlight-body">
                  Our experts offer tailored solutions to realize your vision,
                  from conceptualization to execution.
                </p>
              </div>
            </div>
            <div className="work-process-highlight-grid-item">
              <div className="work-process-highlight-icon-container">
                <CheckOutlinedIcon className="work-process-highlight-icon" />
              </div>
              <div className="work-process-text-container">
                <p className="work-process-highlight-title">Styling</p>
                <p className="work-process-highlight-body">
                  Let your home reflect your personality and style with our
                  expert guidance and design solutions.
                </p>
              </div>
            </div>
          </div>
          <Button
            onClick={() => onExploreMore("before-after-view")}
            variant="outlined"
            color="primary"
            endIcon={<ExpandMoreOutlinedIcon />}
            style={{ marginTop: "32px" }}
          >
            Explore
          </Button>
        </div>
        <div className="landing-screen-bg-video-container animate__animated animate__fadeInLeft">
          {/* <div className="landing-screen-bg-video-background-2"></div> */}
          <video
            className="landing-screen-bg-video"
            playsinline
            autoplay="autoplay"
            muted="muted"
            oncanplay="this.muted=true"
          >
            <source
              src="https://elitenooks.com/uploads/videos/landing/elitenooks_landing_page_video.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <p className="landing-screen-tagline brand-font-primary large-screen-only animate__animated animate__fadeInLeft">
          Luxury in every corner
        </p>
        {/* <div className="landing-screen-description-container">
          <ul style={{ textAlign: "left" }}>
            <li>Planning</li>
            <li>Execution</li>
            <li>Styling</li>
            <li>Planning</li>
            <li>Execution</li>
            <li>Styling</li>
            <li>Planning</li>
            <li>Execution</li>
          </ul>
        </div> */}
        {/* <p className="landing-screen-tagline brand-font-primary">
          Luxury in every corner
        </p> */}
      </div>

      {/* <div
        className={`landing-page-social-media-links-container ${
          visible ? "visible" : ""
        }`}
      >
        <a href={contactDetails.socialMedia.instagram} target="_blank">
          <InstagramIcon className="landing-page-social-media-icon" />
        </a>
        <a href={contactDetails.socialMedia.facebook} target="_blank">
          <FacebookIcon className="landing-page-social-media-icon" />
        </a>
        <a href={contactDetails.socialMedia.twitter} target="_blank">
          <XIcon className="landing-page-social-media-icon" />
        </a>
      </div> */}
    </div>
  );
}

export default Landing;
