import "./WhyChooseUs.css";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CenterFocusWeakOutlinedIcon from "@mui/icons-material/CenterFocusWeakOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";

function WhyChooseUs() {
  function onExploreMore(path) {
    const element = document.getElementById(path);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }

  return (
    <>
      <div className="why-choose-us-parent-container">
        <div className="why-choose-us-inner-container max-inner-width">
          <p className="why-choose-us-section-title brand-font-primary">
            Why Elitenooks?
          </p>
          <a
            rel="noreferrer"
            onClick={() => onExploreMore("packages-view")}
            className="why-choose-us-to-about-us-cta"
          >
            {/* <p>Get a quote now</p> */}
            <p>Explore packages</p>
            <ArrowForwardOutlinedIcon className="why-choose-us-to-about-us-cta-icon" />
          </a>
          <div className="why-choose-us-highlights-grid-view">
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <SpaceDashboardOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">
                Detailed functional designs
              </p>
              <p className="why-choose-us-highlight-body">
                Explore meticulously crafted designs that seamlessly blend
                functionality with aesthetics, tailored to elevate your living
                spaces.
              </p>
            </div>
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <HomeOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">Walk-in homes</p>
              <p className="why-choose-us-highlight-body">
                Step into our walk-in homes section and envision yourself in
                spaces designed to inspire and captivate, offering a glimpse
                into your dream lifestyle.
              </p>
            </div>
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <StyleOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">Completely styled</p>
              <p className="why-choose-us-highlight-body">
                Indulge in our collection of completely styled interiors, where
                every corner reflects sophistication and elegance, curated to
                perfection.
              </p>
            </div>
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <AutoAwesomeOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">
                High quality standards
              </p>
              <p className="why-choose-us-highlight-body">
                Experience excellence with our high-quality standards, ensuring
                durability, craftsmanship, and timeless appeal in every aspect
                of design.
              </p>
            </div>
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <CenterFocusWeakOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">
                Attention to details
              </p>
              <p className="why-choose-us-highlight-body">
                Immerse yourself in our designs crafted with meticulous
                attention to detail, where every element contributes to a
                harmonious and cohesive space.
              </p>
            </div>
            <div
              className="why-choose-us-highlight-grid-item"
              data-aos="fade-up"
            >
              <div className="why-choose-us-highlight-icon-container">
                <ExploreOutlinedIcon className="why-choose-us-highlight-icon" />
              </div>
              <p className="why-choose-us-highlight-title">
                Vaastu compliant designs
              </p>
              <p className="why-choose-us-highlight-body">
                Discover our Vaastu compliant designs that honor ancient
                principles while embracing modern aesthetics, creating homes
                that resonate with positive energy and balance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs;
