import "./SimilarProjects.css";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Autoplay, A11y, EffectFade, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";

function SimilarProjects() {
  const { id } = useParams();
  const [similarProjectsData, setSimilarProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSimilarProjectsDetails();
  }, []);

  async function fetchSimilarProjectsDetails() {
    try {
      setLoading(true);
      const response = await axios.get(
        // `https://elitenooks.com/api/get_similar_projects.php?id=${id}`
        `https://elitenooks.com/api/get_similar_projects.php?id=${id}&package_id=1&size=2bhk&location=pune`
      );
      if (response.status === 200) {
        setSimilarProjectsData(response.data?.projects);
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  }

  const navigate = useNavigate();

  function onOpenProject(id) {
    navigate(`/project/${id}`);
  }

  return (
    <div
      id="similar-projects-view"
      className="projects-slider-container max-inner-width-reduced"
    >
      <div className="section-title">
        <p className="section-title-text brand-font-primary">
          Similar Projects
        </p>
        <p className="section-subtitle-text">
          Take a look at more projects like this
        </p>
      </div>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="projects-slideshow"
      >
        {similarProjectsData?.map((project, index) => (
          <SwiperSlide
            className="project-card"
            key={index}
            onClick={() => onOpenProject(project.id)}
          >
            <img
              className="project-picture"
              src={project?.key_picture}
              alt={index}
            />
            <div className="gradient-container"></div>
            <div className="project-details">
              {/* <div
                className="project-picture-container"
                style={{
                  flex: 1,
                }}
              >
                <img
                  className="project-picture"
                  src={project?.key_picture}
                  alt={index}
                />
              </div> */}
              <p className="title">{project?.title}</p>
              <div style={{ flex: 1 }}></div>
              <div className="common-divider-light"></div>
              <p className="subtitle">{project?.located_at}</p>
              <div className="project-details-container">
                <div className="project-details-item">
                  <WidgetsOutlinedIcon className="project-details-icon" />
                  <p>{project?.package?.title}</p>
                </div>
                <div className="project-details-item">
                  <MeetingRoomOutlinedIcon className="project-details-icon" />
                  <p>{project?.size}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SimilarProjects;
