import "./PackageSizeSelector.css";
import PackageSizeView2bhk from "../../asset/images/packages-view/elitenooks_package_size_2bhk.png";
import PackageSizeView3bhk from "../../asset/images/packages-view/elitenooks_package_size_3bhk.png";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function PackageSizeSelector() {
  const { type, package_id } = useParams();
  const [selectedSize, setSelectedSize] = useState(+(type ?? 2));
  const [currentHoverLabel, setCurrentHoverLabel] = useState("");

  // For 3 BHK
  const imageRef3Bhk = useRef(null);
  const originalWidth3Bhk = 1920;
  const originalHeight3Bhk = 1489;

  // For 3 BHK
  const imageRef2Bhk = useRef(null);
  const originalWidth2Bhk = 1920;
  const originalHeight2Bhk = 1278;

  const scaleFactorLargeScreen = 0.6;
  const scaleFactorSmallScreen = 0.9;

  useEffect(() => {
    // For map overlay debugging
    // var cv = document.getElementById("map-hover-canvas");
    // var context = cv.getContext("2d");
    // context.clearRect(0, 0, cv.width, cv.height);
    // var areas = document.getElementsByClassName("map-hover-area");

    // for (var i = 0; i < areas.length; i++) {
    //   var coordStr = areas[i].getAttribute("coords");
    //   drawPolygon(context, coordStr);
    // }

    // For hover overlay effect

    // Room hover tooltip
    var tooltip = document.getElementById("room-hover-tooltip");

    // 2BHK
    var canvas2bhk = document.getElementById("map-hover-canvas-2bhk");
    var context2bhk = canvas2bhk.getContext("2d");
    context2bhk.clearRect(0, 0, canvas2bhk.width, canvas2bhk.height);

    var onMouseHoveringMapArea = function (e) {
      tooltip.style.left = e.pageX + "px";
      tooltip.style.top = e.pageY + "px";
    };

    var onMouseHover2bhk = function (e) {
      setCurrentHoverLabel(e.currentTarget.getAttribute("data-label"));
      tooltip.style.display = "flex";
      tooltip.style.left = e.pageX + "px";
      tooltip.style.top = e.pageY + "px";
      var coordStr = this.getAttribute("coords");
      drawPolygon(context2bhk, coordStr);
    };
    var onMouseOut2bhk = function () {
      tooltip.style.display = "none";
      context2bhk.clearRect(0, 0, canvas2bhk.width, canvas2bhk.height);
    };

    var areas2bhk = document.getElementsByClassName("map-hover-area-2bhk");
    for (var i = 0; i < areas2bhk.length; i++) {
      areas2bhk[i].addEventListener("mouseover", onMouseHover2bhk, false);
      areas2bhk[i].addEventListener("mouseout", onMouseOut2bhk, false);
      areas2bhk[i].addEventListener("mousemove", onMouseHoveringMapArea, false);
    }

    // 3BHK
    var canvas3bhk = document.getElementById("map-hover-canvas-3bhk");
    var context3bhk = canvas3bhk.getContext("2d");
    context3bhk.clearRect(0, 0, canvas3bhk.width, canvas3bhk.height);

    var onMouseHover3bhk = function (e) {
      setCurrentHoverLabel(e.currentTarget.getAttribute("data-label"));
      tooltip.style.display = "flex";
      tooltip.style.left = e.pageX + "px";
      tooltip.style.top = e.pageY + "px";
      var coordStr = this.getAttribute("coords");
      drawPolygon(context3bhk, coordStr);
      // debugger;
    };
    var onMouseOut3bhk = function () {
      tooltip.style.display = "none";
      context3bhk.clearRect(0, 0, canvas3bhk.width, canvas3bhk.height);
    };

    var areas3bhk = document.getElementsByClassName("map-hover-area-3bhk");
    for (var j = 0; j < areas3bhk.length; j++) {
      areas3bhk[j].addEventListener("mouseover", onMouseHover3bhk, false);
      areas3bhk[j].addEventListener("mouseout", onMouseOut3bhk, false);
      areas3bhk[j].addEventListener("mousemove", onMouseHoveringMapArea, false);
    }

    // For responsive image map
    const resizeMap = () => {
      var parentContainers = document.getElementsByClassName(
        "package_size_container"
      );

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const newRatio =
        windowWidth > 800
          ? (windowWidth / originalWidth3Bhk) * scaleFactorLargeScreen
          : (windowWidth / originalWidth3Bhk) * scaleFactorSmallScreen;

      for (var i = 0; i < parentContainers.length; i++) {
        parentContainers[i].style.transform = `scale(${newRatio})`;
      }
    };

    const handleResize = () => {
      resizeMap();
    };

    resizeMap();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function drawPolygon(hdc, coOrdStr) {
    var mCoords = coOrdStr.split(",");
    var i, n;
    n = mCoords.length;

    hdc.beginPath();
    hdc.moveTo(mCoords[0], mCoords[1]);
    for (i = 2; i < n; i += 2) {
      hdc.lineTo(mCoords[i], mCoords[i + 1]);
    }
    hdc.lineTo(mCoords[0], mCoords[1]);
    hdc.closePath();
    hdc.globalCompositeOperation = "multiply";
    hdc.fillStyle = "#574528AA";
    hdc.fill();
  }

  function onSizeSelected(e, size) {
    setSelectedSize(size ?? 0);
  }

  return (
    <>
      <div id="room-hover-tooltip" className="room_hover_tooltip">
        <h3>{currentHoverLabel}</h3>
        <p>Click to explore more</p>
      </div>

      <div className="package_size_page">
        <div className="package-size-selection-container">
          <p>Select size of your home: </p>
          <Tabs value={selectedSize} onChange={onSizeSelected} centered>
            <Tab label="2 BHK" value={2} />
            <Tab label="3 BHK" value={3} />
          </Tabs>
        </div>

        {/* 2 BHK */}
        <div
          className="package_size_container_parent animate__animated animate__fadeInUp"
          style={{ display: selectedSize === 2 ? "flex" : "none" }}
        >
          <div
            id="package_size_container_2bhk"
            className="package_size_container"
          >
            {/* 3D view image */}
            <img
              ref={imageRef2Bhk}
              className="package-size-picture"
              src={PackageSizeView2bhk}
              alt="elitenooks-package-2bhk"
            />

            {/* Map hover overlay canvas */}
            <canvas
              id="map-hover-canvas-2bhk"
              className="map-hover-canvas"
              width={originalWidth2Bhk}
              height={originalHeight2Bhk}
            >
              Canvas is not supported by your browser.
            </canvas>

            {/* Overlay image for map hover detection */}
            <img
              className="package-size-picture"
              usemap="#image-map-2bhk"
              alt=""
              style={{ opacity: "0" }}
            />

            <map
              className="elitenooks-package-map"
              id="image-map-2bhk"
              name="image-map-2bhk"
            >
              <area
                alt=""
                className="map-hover-area-2bhk"
                target="_self"
                data-label="Living and Dining"
                href={`/packages-explore/2/${package_id ?? 1}/1`}
                coords="290,448,288,307,572,100,772,163,770,316,1165,453,962,599,962,662,933,683"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-2bhk"
                target="_self"
                data-label="Kitchen"
                href={`/packages-explore/2/${package_id ?? 1}/3`}
                coords="1221,466,1306,405,1306,349,1510,199,979,15,775,163,773,312"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-2bhk"
                target="_self"
                data-label="Master Bedroom"
                href={`/packages-explore/2/${package_id ?? 1}/4`}
                coords="1007,993,1401,1125,1759,864,1287,709,1287,793"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-2bhk"
                target="_self"
                data-label="Bedroom"
                href={`/packages-explore/2/${package_id ?? 1}/5`}
                coords="607,859,1001,993,1270,798,875,667"
                shape="poly"
              />
              {/* <area
                className="map-hover-area-2bhk"
                target="_self"
                alt="Gallery from Living"
                title="Gallery from Living"
                href=""
                coords="17,508,15,799,288,895,288,753,565,554,283,454,282,310"
                shape="poly"
              />
              <area
                className="map-hover-area-2bhk"
                target="_self"
                alt="Bathroom Master Bedroom"
                title="Bathroom Master Bedroom"
                href=""
                coords="1455,761,1589,664,1591,512,1903,614,1900,760,1762,863"
                shape="poly"
              />
              <area
                className="map-hover-area-2bhk"
                target="_self"
                alt="Bathroom Common"
                title="Bathroom Common"
                href=""
                coords="1024,558,1235,631,1236,476,1318,417,1588,510,1586,661,1450,758,1282,703,1282,797,965,693,967,600"
                shape="poly"
              /> */}
            </map>
          </div>
        </div>

        {/* 3 BHK */}
        <div
          className="package_size_container_parent animate__animated animate__fadeInUp"
          style={{ display: selectedSize === 3 ? "flex" : "none" }}
        >
          <div
            id="package_size_container_3bhk"
            className="package_size_container"
          >
            {/* 3D view image */}
            <img
              ref={imageRef3Bhk}
              className="package-size-picture"
              src={PackageSizeView3bhk}
              alt="elitenooks-package-3bhk"
            />

            {/* Map hover overlay canvas */}
            <canvas
              id="map-hover-canvas-3bhk"
              className="map-hover-canvas"
              width={originalWidth3Bhk}
              height={originalHeight3Bhk}
            >
              Canvas is not supported by your browser.
            </canvas>

            {/* Overlay image for map hover detection */}
            <img
              className="package-size-picture"
              usemap="#image-map-3bhk"
              alt=""
              style={{ opacity: "0" }}
            />

            <map
              className="elitenooks-package-map"
              id="image-map-3bhk"
              name="image-map-3bhk"
            >
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Living and Dining"
                href={`/packages-explore/3/${package_id ?? 1}/1`}
                coords="217,798,219,903,677,1105,1169,593,921,484,721,706,494,608,494,505,407,593,407,769,349,832,336,827,334,674"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Master Bedroom"
                href={`/packages-explore/3/${package_id ?? 1}/4`}
                coords="1077,547,1075,440,1303,196,1454,265,1438,279,1615,359,1630,345,1740,391,1547,593,1549,698,1506,747,1269,642,1179,737,1079,691,1174,593"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Bedroom"
                href={`/packages-explore/3/${package_id ?? 1}/5`}
                coords="344,458,651,132,1007,290,977,322,977,417,719,700,497,607,497,497,490,495,473,514"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Guest Bedroom"
                href={`/packages-explore/3/${package_id ?? 1}/6`}
                coords="760,1217,1016,949,1352,1096,1062,1408,892,1329,929,1290"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Kitchen"
                href={`/packages-explore/3/${package_id ?? 1}/3`}
                coords="1077,695,1425,848,1269,1009,1269,1026,1250,1046,889,892"
                shape="poly"
              />
              <area
                alt=""
                className="map-hover-area-3bhk"
                target="_self"
                data-label="Lobby"
                href={`/packages-explore/3/${package_id ?? 1}/2`}
                coords="103,665,249,505,417,577,404,592,404,767,349,828,339,826,338,665,266,739"
                shape="poly"
              />
              {/* <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Gallery Master Bedroom"
                title="Gallery Master Bedroom"
                href=""
                coords="1515,746,1703,831,1701,901,1913,672,1915,495,1727,414,1552,595,1554,700"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Gallery Living"
                title="Gallery Living"
                href=""
                coords="204,790,212,794,214,907,678,1113,570,1230,570,1302,90,1094,90,912,204,790"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Gallery Bedroom"
                title="Gallery Bedroom"
                href=""
                coords="661,129,780,3,780,119,1050,239,1006,285"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Bathroom Master Bedroom"
                title="Bathroom Master Bedroom"
                href=""
                coords="1187,738,1269,650,1515,758,1433,846"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Bathroom Bedroom"
                title="Bathroom Bedroom"
                href=""
                coords="926,482,992,409,992,324,1074,237,1074,309,1158,344,1072,438,1074,546"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Bathroom Common"
                title="Bathroom Common"
                href=""
                coords="682,1107,828,955,953,1011,807,1162"
                shape="poly"
              />
              <area
                className="map-hover-area-3bhk"
                target="_self"
                alt="Gallery Kitchen"
                title="Gallery Kitchen"
                href=""
                coords="1253,1048,1274,1026,1275,1012,1430,846,1437,849,1454,831,1559,875,1365,1080,1365,1097"
                shape="poly"
              /> */}
            </map>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageSizeSelector;
