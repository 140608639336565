import "./MessageBox.css";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

function MessageBox({ title, message }) {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/");
  }

  return (
    <>
      <div className="Page MessageBox">
        <p className="Title">{title ?? "Alert"}</p>
        <p className="Subtitle">{message ?? ""}</p>
        <Button
          onClick={goToHome}
          variant="outlined"
          color="primary"
          startIcon={<HomeOutlinedIcon />}
        >
          Go to home
        </Button>
      </div>
    </>
  );
}

export default MessageBox;
