import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Projects.css";

import Button from "@mui/material/Button";
import Loader from "../../components/Loader/Loader";
import MessageBox from "../../components/MessageBox/MessageBox";

import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import Packages from "../../components/Packages/Packages";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import CtaConnectWithUs from "../../components/CtaConnectWithUs/CtaConnectWithUs";

function Projects() {
  const { id } = useParams();
  const [viewsGalleryController, setViewsGalleryController] = useState({
    toggler: false,
    slide: 1,
  });
  const [visualsGalleryController, setVisualsGalleryController] = useState({
    toggler: false,
    slide: 1,
  });
  const [loading, setLoading] = useState(true);
  const [projectsData, setProjectsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  async function fetchProjects() {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://elitenooks.com/api/get_projects.php",
        {
          validateStatus: function (_) {
            return true;
          },
        }
      );
      if (response.status === 200) {
        setProjectsData(response.data.projects);
      } else {
        //TODO: Handle this
      }
    } catch (e) {
      //TODO:  Handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function onOpenProject(id) {
    navigate(`/project/${id}`);
  }

  function onGoToContacts() {
    navigate(`/contact`);
    window.scrollTo(0, 0);
  }

  if (loading) return <Loader />;

  return (
    <>
      {projectsData ? (
        <div className="projects-parent-container">
          <div className="projects-container">
            <div className="projects-inner-container">
              <div className="section-title">
                <p className="section-title-text brand-font-primary">
                  Explore our work
                </p>
                {/* <p className="section-subtitle-text">
                  Experience our work through various projects below
                </p> */}
              </div>
              <p className="projects-section-title max-inner-width-reduced">
                Featured Projects
              </p>
              <div className="projects-grid-view featured max-inner-width">
                {projectsData
                  ?.filter((item) => item.featured)
                  ?.map((project, index) => (
                    <div
                      className="project-grid-item"
                      key={index}
                      onClick={() => onOpenProject(project.id)}
                    >
                      <img
                        className="project-grid-item-picture"
                        src={project?.key_picture}
                        alt={index}
                        loading="lazy"
                      />
                      <div className="gradient-container"></div>
                      <div className="project-grid-item-details">
                        <p className="title">{project?.title}</p>
                        <p className="subtitle">{project?.located_at}</p>
                        <div className="common-secondary-divider" />
                        <div className="explore-button">
                          <p>Click to explore more</p>
                          <ArrowRightAltOutlinedIcon />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <p className="projects-section-title max-inner-width-reduced">
                All Projects
              </p>
              <div className="projects-grid-view max-inner-width">
                {projectsData
                  ?.filter((item) => !item.featured)
                  ?.map((project, index) => (
                    <div
                      className="project-grid-item"
                      key={index}
                      onClick={() => onOpenProject(project.id)}
                    >
                      <img
                        className="project-grid-item-picture"
                        src={project?.key_picture}
                        alt={index}
                        loading="lazy"
                      />
                      <div className="gradient-container"></div>
                      <div className="project-grid-item-details">
                        <p className="title">{project?.title}</p>
                        <p className="subtitle">{project?.located_at}</p>
                        <div className="common-secondary-divider" />
                        <div className="explore-button">
                          <p>Click to explore more</p>
                          <ArrowRightAltOutlinedIcon />
                        </div>
                      </div>
                    </div>
                  ))}
                <div
                  className="project-grid-item cta"
                  // key={index}
                  onClick={onGoToContacts}
                >
                  <div className="gradient-container"></div>
                  <div className="project-grid-item-details">
                    <p className="title">Need some help?</p>
                    <p className="subtitle" style={{ fontSize: "0.9em" }}>
                      Click on button below to discuss your home with us!
                    </p>
                    <div className="explore-button">
                      <p>Get a call</p>
                      <ArrowRightAltOutlinedIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Links to other pages */}
        </div>
      ) : (
        <MessageBox
          title="Oops!"
          message="No projects are available at this moment, please try again later"
        />
      )}
    </>
  );
}

export default Projects;
