import "./404.css";

const UnknownPage = () => {
  return (
    <>
      <div className="Page Unknown-page">Unknown</div>
    </>
  );
};

export default UnknownPage;
